<template>
	<div>
		<AppHeader />
		<div class="login-content" :style="height">
			<div class="login-box" :style="marginLeft">
				<div class="login-box-left">
					<p class="title">编辑资料</p>
					<el-form :model="dataForm" :rules="dataRule" label-width="80px" ref="dataForm">
						<el-form-item label="用户姓名" prop="name">
							<el-input size="small" v-model="dataForm.name" placeholder="请输入姓名" maxlength="32" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="tel">
							<el-input size="small" v-model="dataForm.tel" placeholder="请输入手机号" maxlength="32"></el-input>
						</el-form-item>
						<el-form-item label="邮箱地址" prop="email">
							<el-input size="small" v-model="dataForm.email" placeholder="请输入邮箱地址" maxlength="200"></el-input>
						</el-form-item>
						<el-form-item label="用户ID" prop="code">
							<el-input size="small" v-model="dataForm.code" placeholder="建议使用姓名拼音作为用户ID" maxlength="32" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="学员ID" prop="stuId">
							<el-input size="small" v-model="dataForm.stuId" placeholder="学员ID" maxlength="32" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="设置密码" prop="pass">
							<el-input size="small" v-model="dataForm.pass" type="password" auto-complete="new-password" placeholder="请设置密码" maxlength="32" @paste.native.capture.prevent="handlePaste"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="confirmPass">
							<el-input size="small" v-model="dataForm.confirmPass" type="password" auto-complete="new-password" placeholder="请确认密码" maxlength="32" @paste.native.capture.prevent="handlePaste"></el-input>
						</el-form-item>
						<el-form-item label="所属区域" prop="areaId">
							<el-input size="small" v-model="dataForm.areaName" placeholder="所属公司" maxlength="200" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="所属公司" prop="agentId">
							<el-input size="small" v-model="dataForm.agentName" placeholder="所属公司" maxlength="200" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item>
							<button class="back-btn" type="button" @click="back">返回</button>
							<button class="save-btn" type="button" @click="handleSubmit">保存</button>
						</el-form-item>
					</el-form>

				</div>
				<img class="login-box-right" src="@/assets/images/beijing.png" alt="">
			</div>
			<p class="banquan">版权所有 © 2021理光（中国）投资有限公司</p>
			<p class="beian">备案编号：沪ICP备08111514号</p>
		</div>
	</div>
</template>
<script>
import AppHeader from '@/layout/components/AppHeader'
import { debounce } from '@/utils/debounce'
export default {
	components:{
        AppHeader
	},
	data() {
		return {
			account: '',
			password: '',
			passFlag: true,
			checked: true,
			height: '',
			marginLeft: '',
			dataForm: {
				name: '',
				tel: '',
				email: '',
				code: '',
				stuId: '',
				areaId: '1',
				areaName: '',
				agentId: '1',
				agentName: '',
				pass: '',
				confirmPass: '',
				id: ''
			},
			dataRule: {
				name: [
					{required: true,message: '用户姓名不能为空', trigger: "blur"},
					{pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '请输入中文，英文和数字'}
				],
				// tel: [
				// 	{required: true,message: '手机号码不能为空', trigger: "blur"},
				// 	{pattern: /^[0-9]*$/, message: '请输入正确手机格式'}
				// ],
				email: [
					{required: true,message: '邮箱不能为空', trigger: "blur"},
					{pattern: /^[A-Za-z0-9_.-]*([@]+)[A-Za-z0-9_.-]*$/, message: '请输入正确邮箱格式'},
				],
				areaId: [
					{required: true,message: '手机号码不能为空', trigger: "blur"},
				],
				agentId: [
					{required: true,message: '邮箱不能为空', trigger: "blur"}
				],
			}
		}
	},
	created() {
		this.height = 'min-height:' + (window.innerHeight - 80) + 'px;'
		this.marginLeft = 'margin-left:' + (window.innerWidth/2 - 450) + 'px;'
	},
	mounted() {
		this.init();
	},
	methods: {
		// 初始数据
		init() {
			let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
			this.dataForm.id = userInfo.id;
			this.dataForm.name = userInfo.name;
			this.dataForm.tel = userInfo.tel;
			this.dataForm.email = userInfo.email;
			this.dataForm.stuId = userInfo.stuId;
			this.dataForm.code = userInfo.code;
			this.dataForm.areaName = userInfo.areaName;
			this.dataForm.agentName = userInfo.agentName;
		},
		back() {
			this.$router.push({
				name:'home'
			})
		},
		handlePaste() {},
		// 提交
		handleSubmit:debounce(function() {
			if(this.dataForm.pass != this.dataForm.confirmPass) {
				this.$message.error('设置密码和确认密码不一致');
				return
			}
			// 定义密码长度限制和复杂性要求的正则表达式
			var regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%#?&.？！])[A-Za-z\d@$!%#?&.？！]{12,}$/;
			if(this.dataForm.pass != '' && !regex.test(this.dataForm.pass)) {
				this.$message.error('请输入新密码，至少包含12个字符。其中必须有字母、数字和以下的特殊符号 @ $ ! % # ? & .');
				return
			}
			let formObj = {
				id: this.dataForm.id,
				tel: this.dataForm.tel,
				email: this.dataForm.email,
				pass: this.dataForm.pass,
				confirmPass: this.dataForm.confirmPass,
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						'sysEditUserInfo',
						true,
						formObj,
						function (r) {
							if (r.code == "0") {
								let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
								userInfo.email = that.dataForm.email;
								userInfo.tel = that.dataForm.tel;
								window.sessionStorage.setItem('userInfo',JSON.stringify(userInfo));
								if(that.dataForm.pass != '') {
									that.$message.success('保存成功,请重新登录');
									window.sessionStorage.clear();
									that.$router.push({
										name:'login'
									})
								}else {
									that.$message.success('保存成功');
								}
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.lh-17{
	line-height: 17px;
}
.el-form{
	box-sizing: border-box;
	padding: 0 40px;
}
.el-form-item{
	margin-bottom: 15px;
	.back-btn{
		float: left;
		width: 30%;
		height: 38px;
		background: rgba(17, 127, 252, 0.2);
		border-radius: 5px;
		color: #117FFC;
		border: none;
		outline: none;
		cursor: pointer;
	}
	.save-btn{
		float: right;
		width: 60%;
		height: 38px;
		background: rgba(17, 127, 252, 1);
		border-radius: 5px;
		color: #fff;
		border: none;
		outline: none;
		cursor: pointer;
	}
}
.login-content{
	width: 100%;
	background: url('~@/assets/images/beijing2.png') no-repeat;
	background-color: #ECF7FF;
	background-size: contain;
	background-position: center;
	box-sizing: border-box;
	padding-top: 150px;
	padding-bottom: 20px;
	.login-box{
		width: 925px;
		height: 630px;
		border-radius: 20px;
		background-color: #fff;
		position: relative;
		.login-box-left{
			display: inline-block;
			width: 60%;
			height: 100%;
			.title{
				text-align: center;
				margin: 35px 0;
				font-weight: bold;
				color: #555;
			}
		}
		.login-box-right{
			position: absolute;
			right: 0;
			top: 0;
			display: inline-block;
			width: 40%;
			height: 100%;
		}
	}
}
.banquan{
	text-align: center;
	color: #A8A8A8;
	margin-left: -159px;
	margin-top: 25px;
}
.beian{
	text-align: center;
	color: #0067FF;
	margin-left: -114px;
	margin-top: 10px;
}
.el-form-item /deep/ .el-form-item__label{
	font-size: 13px;
	line-height: 34px;
}
.el-form-item /deep/ .el-form-item__content{
	font-size: 13px;
	line-height: 34px;
}
</style>
